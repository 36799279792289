import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    {
        path: '/',
        name: 'home',
        component: () =>
            import('./pages/Login.vue'),
        meta: {
            plainLayout: true
        },
    },
    
    {
        path: '/login',
        name: 'login',
        exact: true,
        component: () =>
            import('./pages/Login.vue'),
        meta: {
            plainLayout: true,
        },
    },

    {
        path: '/forgotpassword',
        name: 'forgotpassword',
        component: () =>
            import('./pages/ForgotPassword.vue'),
        meta: {
            plainLayout: true,
        },
    },
    {
        path: '/clients',
        name: 'listclient',
        exact: true,
        component: () =>
            import('./components/ListClient.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ label: 'Client' }],
        },
    },
    {
        path: '/clients/dashboard/:clientId?',
        name: 'clientdashboard',
        exact: true,
        component: () =>
            import('./components/clienttabs/ClientDashboard.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Client', label: 'Dashboard' }],
        },
    },
    {
        path: '/clients/dashboard/:clientSubId?/clientinfo',
        name: 'clientinfo',
        exact: true,
        component: () =>
            import('./components/clienttabs/ClientInfoTab.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Dashboard', label: 'Client Info' }],
        },
    },
    {
        path: '/clients/dashboard/:clientSubId?/clientconfig',
        name: 'clientconfig',
        exact: true,
        component: () =>
            import('./components/clienttabs/ClientConfigTab.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Dashboard', label: 'Client Config' }],
        },
    },
    {
        path: '/clients/dashboard/:clientSubId?/clientplan',
        name: 'clientplan',
        exact: true,
        component: () =>
            import('./components/clienttabs/ClientPlanTab.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Client', label: 'Client Plan' }],
        },
    },
    {
        path: '/clients/dashboard/:clientSubId?/addfund',
        name: 'clientaddfund',
        exact: true,
        component: () =>
            import('./components/clienttabs/AddFundTab.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Client', label: 'Add Fund' }],
        },
    },
    {
        path: '/clients/dashboard/:clientSubId?/client-role',
        name: 'client-role',
        exact: true,
        component: () =>
            import('./components/clienttabs/ClientRole.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Dashboard', label: 'Client Role' }],
        },
    },
    {
        path: '/clients/dashboard/:clientSubId?/client-document',
        name: 'client-document',
        exact: true,
        component: () =>
            import('./components/clienttabs/ClientDocument.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Dashboard', label: 'Client Document',  }],
        },
    },
    {
        path: '/clients/dashboard/:clientSubId?/service-setting',
        name: 'service-setting',
        exact: true,
        component: () =>
            import('./components/clienttabs/ServiceSetting.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Dashboard', label: 'Service Setting' }],
        },
    },
    {
        path: '/clients/dashboard/:clientSubId?/add-debit',
        name: 'add-debit',
        exact: true,
        component: () =>
            import('./components/clienttabs/AddDebit.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Dashboard', label: 'Add Debit' }],
        },
    },
    {
        path: '/clients/dashboard/:clientSubId?/template-actions',
        name: 'template-actions',
        exact: true,
        component: () =>
            import('./components/clienttabs/TemplateActions.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Client', label: 'Template Actions' }],
        },
    },
    {
        path: '/clients/dashboard/:clientSubId?/whatsapp-template-settings',
        name: 'whatsapp-template-settings',
        exact: true,
        component: () =>
            import('./components/clienttabs/WhatsAppTemplateSettings.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Client', label: 'WhatsApp Template Settings' }],
        },
    },
    {
        path: '/clients/dashboard/:clientSubId?/RCS-template-settings',
        name: 'RCS-template-settings',
        exact: true,
        component: () =>
            import('./components/clienttabs/RCSTemplateSettings.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Client', label: 'RCS Template Settings' }],
        },
    },
    {
        path: '/clients/dashboard/:clientSubId?/web-bot-template-settings-dashboard',
        name: 'web-bot-template-settings-dashboard',
        exact: true,
        component: () =>
            import('./components/clienttabs/WebBotTemplateSettingDashboard.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Client', label: 'Web Bot Template Settings Dashboard' }],
        },
    },
    {
        path: '/clients/dashboard/:clientSubId?/:chatbotId?/web-bot-template-settings',
        name: 'web-bot-template-settings',
        exact: true,
        component: () =>
            import('./components/clienttabs/WebBotTemplateSettings.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Client', label: 'Web Bot Template Settings' }],
        },
    },
    {
        path: '/billing/plan',
        name: 'billingplan',
        exact: true,
        component: () =>
            import('./components/BillingPlan.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Billing', label: 'Plan' }],
        },
    },
    {
        path: '/billing/clientPayment',
        name: 'clientpayment',
        exact: true,
        component: () =>
            import('./components/ClientPayment.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Billing', label: 'Client Payment' }],
        },
    },
    {
        path: '/billing/plan/component/:planrowId?',
        name: 'billingplancomponent',
        exact: true,
        component: () =>
            import('./components/billing/PlanComponent.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Billing', label: 'Components' }],
        },
    },
    {
        path: '/billing/taxinvoice',
        name: 'billinginvoice',
        exact: true,
        component: () =>
            import('./components/TaxInvoice.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Billing', label: 'Tax Invoice' }],
        },
    },
    {
        path: '/billing/receipt',
        name: 'billingreceipt',
        exact: true,
        component: () =>
            import('./components/PaymentReceipt.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Billing', label: 'Payment Receipt' }],
        },
    },
    {
        path: '/billing/clientbalance',
        name: 'clientbalance',
        exact: true,
        component: () =>
            import('./components/LiveBalance.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Billing', label: 'Clients Live Balance' }],
        },
    },
    {
        path: '/parliament/loksabha',
        name: 'parloksabha',
        exact: true,
        component: () =>
            import('./components/parliament/LokSabha.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Parliament', label: 'Lok Sabha' }],
        },
    },
    {
        path: '/parliament/vidhansabha',
        name: 'parvidhansabha',
        exact: true,
        component: () =>
            import('./components/parliament/VidhanSabha.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Parliament', label: 'Vidhan Sabha' }],
        },
    },
    {
        path: '/parliament/corporation',
        name: 'parcorporation',
        exact: true,
        component: () =>
            import('./components/parliament/Corporation.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Parliament', label: 'Corporation' }],
        },
    },
    {
        path: '/parliament/zillaparishad',
        name: 'parzillaparishad',
        exact: true,
        component: () =>
            import('./components/parliament/ZillaParishad.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Parliament', label: 'Zilla Parishad' }],
        },
    },
    {
        path: '/parliament/panchayatsamiti',
        name: 'parpanchayatsamiti',
        exact: true,
        component: () =>
            import('./components/parliament/PanchayatSamiti.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Parliament', label: 'Panchayat Samiti' }],
        },
    },
    {
        path: '/parliament/grampanchayat',
        name: 'pargrampanchayat',
        exact: true,
        component: () =>
            import('./components/parliament/GramPanchayat.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Parliament', label: 'Gram Panchayat' }],
        },
    },
    {
        path: '/parliament/areas',
        name: 'parliament-areas',
        exact: true,
        component: () =>
            import('./components/parliament/Area.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Parliament', label: 'Area' }],
        },
    },
    {
        path: '/users',
        name: 'listusers',
        exact: true,
        component: () =>
            import('./components/Users.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Users', label: 'List Users' }],
        },
    },
    {
        path: '/empty',
        name: 'empty',
        component: () =>
            import('./components/EmptyPage.vue'),
        meta: {
            requiresAuth: true,
            breadcrumb: [{ parent: 'Pages', label: 'Empty Page' }],
        },
    },
   
    {
        path: '/:pathMatch(.*)',
        name: 'notfound',
        component: () =>
            import('./pages/NotFound.vue'),
        meta: {
            plainLayout: true,

        },
    },
    {
        path: '/D_I_D/Numbers/free-num%bers',
            name: 'freeNumbers',
            exact: true,
            component: () =>
                import('./components/cloudtelephony/freeDIDNumbers.vue'),
            meta: {
                requiresAuth: false,
                checkModuleAccess: false,
                breadcrumb: [{ parent: 'Cloud Telephony', label: 'Free Numbers' }],
            },
    },
    {
        path: '/D_I_D/Numbers/client-num%bers',
            name: 'clientNumbers',
            exact: true,
            component: () =>
                import('./components/cloudtelephony/clientDIDNumbers.vue'),
            meta: {
                requiresAuth: false,
                checkModuleAccess: false,
                breadcrumb: [{ parent: 'Cloud Telephony', label: 'Client Numbers' }],
            },
    },
    {
        path: '/D_I_D/Numbers/notice-period',
            name: 'underNoticePeriodNumbers',
            exact: true,
            component: () =>
                import('./components/cloudtelephony/underNoticePeriodNumbers.vue'),
            meta: {
                requiresAuth: false,
                checkModuleAccess: false,
                breadcrumb: [{ parent: 'Cloud Telephony', label: 'Under Notice Period Numbers' }],
            },
    },
    {
        path: '/D_I_D/Numbers/submitted-request-to-operator',
            name: 'submittedRequestToOperator',
            exact: true,
            component: () =>
                import('./components/cloudtelephony/requestSubmittedToOperator.vue'),
            meta: {
                requiresAuth: false,
                checkModuleAccess: false,
                breadcrumb: [{ parent: 'Cloud Telephony', label: 'Request Submitted To Operator' }],
            },
    },
    {
        path: '/D_I_D/Numbers/released-numbers',
            name: 'releasedNumbers',
            exact: true,
            component: () =>
                import('./components/cloudtelephony/releasedNumbers.vue'),
            meta: {
                requiresAuth: false,
                checkModuleAccess: false,
                breadcrumb: [{ parent: 'Cloud Telephony', label: 'Released Numbers' }],
            },
    },
    {
        path: '/support-tickets/new-ticket',
            name: 'newTicktes',
            exact: true,
            component: () =>
                import('./components/supportTickets/newTickets.vue'),
            meta: {
                requiresAuth: false,
                checkModuleAccess: false,
                breadcrumb: [{ parent: 'Support Ticktes', label: 'New Tickets' }],
            },
    },
    {
        path: '/ClientEmployee',
        name: 'listclientemployee',
        exact: true,
        component: () =>
            import('./components/ClientEmployee.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Client Employees', label: 'List Client Employee' }],
        },
    },
    {
        path: '/settings/DefaultTunes',
        name: 'listdefaulttunes',
        exact: true,
        component: () =>
            import('./components/settings/DefaultTunes.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Settings', label: ' Default Tunes' }],
        },
    },
    {
        path: '/parliament/pincode',
        name: 'listpincode',
        exact: true,
        component: () =>
            import('./components/parliament/Pincode.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Parliament', label: ' Pincode' }],
        },
    },
    {
        path: '/clienttabs/templateevent',
        name: 'listemplateevent',
        exact: true,
        component: () =>
            import('./components/clienttabs/TemplateEvent.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'ClientTabs', label: ' Template Event' }],
        },
    },
    {
        path: '/clienttabs/dashboard/:clientSubId?/TemplateDescription',
        name: 'listTemplateDescription',
        exact: true,
        component: () =>
            import('./components/clienttabs/TemplateDescription.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Client', label: ' Web Bot Template Groups' }],
        },
    },
    {
        path: '/parliament/district',
        name: 'listdistrict',
        exact: true,
        component: () =>
            import('./components/parliament/District.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Parliament', label: ' District' }],
        },
    },
    {
        path: '/client/action/event/:eventId?',
        name: 'addEditEventTemplate',
        exact: true,
        component: () =>
            import('./components/clienttabs/AddEditEventTemplate.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Client', label: 'Event Templates' }],
        },
    },
    
    {
        path: '/support-tickets/open-ticket',
            name: 'openTicktes',
            exact: true,
            component: () =>
                import('./components/supportTickets/openTickets.vue'),
            meta: {
                requiresAuth: false,
                checkModuleAccess: false,
                breadcrumb: [{ parent: 'Support Ticktes', label: 'Open Tickets' }],
            },
    },

    {
        path: '/support-tickets/closed-ticket',
            name: 'closedTicktes',
            exact: true,
            component: () =>
                import('./components/supportTickets/closedTickets.vue'),
            meta: {
                requiresAuth: false,
                checkModuleAccess: false,
                breadcrumb: [{ parent: 'Support Ticktes', label: 'Closed Tickets' }],
            },
    },  
    {
        path: '/settings/AdminSMSAndEmailSetting',
        name: 'listAdminTemplateActions',
        exact: true,
        component: () =>
            import('./components/settings/AdminSMSAndEmailSetting.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Settings', label: ' Admin SMS And Email Settings' }],
        },
    },
    {
        path: '/settings/ClientSMSAndEmailSetting',
        name: 'listClientTemplateActions',
        exact: true,
        component: () =>
            import('./components/settings/ClientSMSAndEmailSetting.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Settings', label: ' Client SMS And Email Settings' }],
        },
    },
    {
        path: '/settings/clientTemplateEvent',
        name: 'listclientTemplateEvent',
        exact: true,
        component: () =>
            import('./components/settings/ClientTemplateEvent.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Settings', label: 'Client Event' }],
        },
    }, 
    {
        path: '/settings/action/event/:eventId?',
        name: 'addEditClientEventTemplate',
        exact: true,
        component: () =>
            import('./components/settings/addEditClientEventTemplate.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Settings', label: 'Client Event' }],
        },
    },
    {
        path: '/settings/adminTemplateEvent',
        name: 'listAdminTemplateEvent',
        exact: true,
        component: () =>
            import('./components/settings/AdminTemplateEvent.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Settings', label: 'Template Event' }],
        },
    }, 
    {
        path: '/settings/action/adminevent/:eventId?',
        name: 'addEditAdminEventTemplate',
        exact: true,
        component: () =>
            import('./components/settings/addEditAdminEventTemplate.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Settings', label: 'Event Templates' }],
        },
    },
    {
        path: '/settings/Companies',
        name: 'listofcompanies',
        exact: true,
        component: () =>
            import('./components/settings/Companies.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Settings', label: 'Companies' }],
        },
    },
    {
        path: '/settings/CompaniesSenderId',
        name: 'listofCompaniessenderid',
        exact: true,
        component: () =>
            import('./components/settings/CompaniesSenderId.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Settings', label: 'SMS Sender Id' }],
        },
    }, 
     {
        path: '/settings/ClientCompanies',
        name: 'listofclientcompanies',
        exact: true,
        component: () =>
            import('./components/settings/ClientCompanies.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Settings', label: 'Client Companies' }],
        },
    }, 
    {
        path: '/settings/votersDesignation',
        name: 'listofvotersdesignation',
        exact: true,
        component: () =>
            import('./components/settings/VotersDesignation.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Settings', label: 'Voter Designation' }],
        },
    }, 
    {
        path: '/settings/image-uploader',
        name: 'image-uploader',
        exact: true,
        component: () =>
            import('./components/settings/ImageUploader.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Settings', label: 'Image Uploader' }],
        },
    }, 
    {
        path: '/clients/dashboard/:clientSubId?/whatsapp-numbers',
        name: 'whatsapp-numbers',
        exact: true,
        component: () =>
            import('./components/clienttabs/WhatsappNumbers.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Client', label: 'WhatsApp Numbers' }],
        },
    },
    {
        path: '/clients/dashboard/:clientSubId?/web-bot-flow',
        name: 'Web-bot-flow',
        exact: true,
        component: () =>
            import('./components/clienttabs/WebBotFlowSetting.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Client', label: 'Web Bot Flow' }],
        },
    },
    {
        path: '/clienttabs/dashboard/:clientSubId?/chat-bots-settings',
        name: 'chatbotsettings',
        exact: true,
        component: () =>
            import('./components/clienttabs/ChatBotSettings.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Client', label: ' Chat Bot Settings' }],
        },
    },
    {
        path: '/clients/dashboard/:clientSubId?/banner-images',
        name: 'banner-images',
        exact: true,
        component: () =>
            import('./components/clienttabs/BannerImage.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Client', label: 'Lokneta App Banner Images' }],
        },
    },
    {
        path: '/clients/dashboard/:clientSubId?/app-version-setting',
        name: 'app-version-setting',
        exact: true,
        component: () =>
            import('./components/clienttabs/AppVersionSetting.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Client', label: 'Lokneta App Version Settings' }],
        },
    },
    {
        path: '/systems-logs/call-logs',
        name: 'systems-logs-call-logs',
        exact: true,
        component: () =>
            import('./components/systemsLogs/callLogs.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Systems Logs', label: 'Call Logs' }],
        },
    },

];

const router = createRouter({
    history: createWebHistory(),
    routes
});
router.beforeEach((to, from, next) => {
    const authenticatedUser = localStorage.user_reference;
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const checkModuleAccess = to.matched.some(record => record.meta.checkModuleAccess);
    const moduleId = to.meta.moduleId;
    const permission_fks = [1, 11, 12];

    if (requiresAuth && !authenticatedUser) {
        next('/login');
    }
    else {
        if (checkModuleAccess) {
            if (permission_fks.includes(moduleId)) {
                next();
            } else {
                next(false);
            }
        } else {
            next();
        }

    }

});



export default router;